import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { aboutUsLinks } from 'components/Layout/navigation';
import Layout from 'components/Layout';
import Carousel from 'components/Carousel';
import TabNavigator from 'components/TabNavigator';
import { HeaderMedium, BaseTransparentP, BaseP } from 'components/typography';
import { blogPosts } from 'common/blogPostsCovid';
import BlogBaseCard from 'components/blog/BaseCard';
import ArrowButton from 'components/Button/ArrowButton';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  PartnersWrapper,
  WhiteContainer,
  ImageBox,
  GatsbyImage,
} from 'components/pages/Partners/styled';
import { RelativeWrapperWithImage } from 'components/pages/Integrations/styled';
import {
  warehousingCompanies,
  couriersCompanies,
  otherCompanies,
} from 'components/pages/Partners/companies';
import { MobileFilter } from 'components/BackgroundFilter';
import { WidthWrapper } from 'components/wrappers';
import { HeroSection } from 'components/wrappers';
import { BasicDiv } from 'components/wrappers';
import { TextDesc } from 'components/wrappers';
import {
  Wrapper,
  LeftSection,
  RightSection,
  ArticlesContainer,
  PostsWrapper,
} from 'components/pages/Blog/styled';
import HeroBg from 'images/covid-19-disruption/hero-bg.png';
import HeroImage from 'images/international-shipping/hero-image.png';
import FindSolutionsImg from 'images/covid-19-disruption/find-solution-for-the-covid19-impact.png';
import OurSolutionsImg from 'images/covid-19-disruption/our-solutions-shipping-express-fast.png';
import ShipkooTrackingImg from 'images/covid-19-disruption/track-your-shipment-show-your-delivery-status.png';
import RouteIcon1 from 'images/covid-19-disruption/shipkoo-express-1.png';
import RouteIcon2 from 'images/covid-19-disruption/shipkoo-express-2.png';
import RouteIcon3 from 'images/covid-19-disruption/shipkoo-express-3.png';
import RouteIcon4 from 'images/covid-19-disruption/shipkoo-express-4.png';
import RouteIcon5 from 'images/covid-19-disruption/shipkoo-express-5.png';
import RouteLine from 'images/covid-19-disruption/route-line.png';
import UKflag from 'images/covid-19-disruption/uk-flag.png';
import USflag from 'images/covid-19-disruption/us-flag.png';
import Germanyflag from 'images/covid-19-disruption/germany-flag.png';
import Koreaflag from 'images/covid-19-disruption/korea-flag.png';


const DetailContent = styled.div`
  ${HeaderMedium} {
    margin-top: 40px;
    margin-bottom: 80px;
  }
`;

const PageContainer = styled.div<RelativeWrapperWithImage>`
  margin-bottom:0;
  width:100%;
  p {
    color: rgb(75, 75, 75);
  }
  .column-half {
    width: 48%;
    display: inline-block;
    float: left;
    padding-right:4%;
  }

  .column-half.last {
    width: 48%;
    padding-right:0;
  }

  .column-third {
    width:31%;
    display:inline-block;
    margin-right:3%;
    float:left;
  }

  .column-third.last {
    margin-right:0;
  }

  .column-fourth {
    width: 25%;
    display: inline-block;
    float: left;
    padding-right:2%;
  }

  .column-fourth.last {
    padding-right:0;
  }

`;

const BlogRow = styled.div<ArticlesContainer>`
  margin-top:50px;
  border-bottom:1px solid #000;
  padding-bottom:35px;
  max-width:1080px;
  margin-left:auto;
  margin-right:auto;

  ${PostsWrapper} {
      grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
  }

  @media only screen and (max-width:981px) {
    p {
      color:#fff;
    }
  }
`;

const MainSection = styled.div<HeroSection>`
  position:relative;

  #hero-section {
    padding:70px 20px;
  }

  #hero-section .column {
    width: 100%;
    padding-right: 0;
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
    display: inline-block;
    float: left;
  }

  #hero-section .column p{
    padding-right:60px;
  }

  #hero-section .column.last {
    width: 50%;
    padding-right: 0;
    position: relative;
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    float: left;
  }

  #hero-section .column img{
    width: 100%;
  }

  .hero-bg {
    position: absolute;
    top: 0;
    left:0;
    object-fit: cover;
    right:0;
    height: 100%;
    width: 100%;
    bottom: 0;
    float:left;
  }

  @media only screen and (max-width:981px) {
    #hero-section .column {
      width: 100%;
      padding:0;
    }

    #hero-section .column img{
      width: 100%;
    }

    #hero-section .column p{
      padding-right:0;
    }

    #hero-section .column.last {
      width:100%;
      padding-top:25px;
    }

    #hero-section {
      padding:40px 25px;
    }

    #hero-section h1 {
      font-size: 28px;
      margin-bottom: 20px;
    }
  }

`;

const Covid19DisruptionSection = styled.div<HeroSection>`
  padding-top:25px;

  h2 {
    font-size: 25px;
    color: #333;
    font-weight: 600;
    margin-bottom: 25px;
    text-align: center
  }

  ${ArrowButton} { 
    margin-top:75px;
  }

  ul {
    padding-left:0;
    margin-top:5px;
  }

  ul li {
    list-style-type: none;
    padding-left:15px;
    color: #4b4b4b;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom:5px;
  }

  ul li::before {
    content: '•';
    color: #00AF85;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  .text-center {
    text-align:center !important;
  }

  button {
    margin-left:auto;
    margin-right:auto;
  }

  #find-solutions-section {
    padding-top:50px;
    padding-bottom:25px;
  }

  #find-solutions-section .column-half {
    width: 63%;
    position: relative;
    padding-bottom: 0px;
    padding-right:0;
    display: inline-block;
    float: left;
  }

  #find-solutions-section  .column-half.last {
    width: 37%;
    padding-right: 0;
    z-index: 22;
    position: relative;
    display: inline-block;
    padding-bottom: 0;
    padding-left: 0;
    float: left;
  }

  #find-solutions-section img {
    margin-top: -60px;
  }

  #find-solutions-section h2 {
    font-size: 25px;
    color: #333;
    font-weight: 600;
    margin-bottom:25px;
    text-align:center;
  }

  #find-solutions-section h1 {
    font-size: 30px;
    color: #00AF85;
    font-weight: 500;
    margin-bottom:35px;
  }

  #signature-routes-section {
    padding-bottom:25px;
    padding-top:60px;
  }

  #signature-routes-section p {
    color:#4b4b4b;
    line-height:23px;
    font-size:15px;
  }

  #signature-routes-section .route-icon {
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
    margin-top:15px;
    margin-bottom:15px;
  }

  #signature-routes-section h2 {
    font-size: 25px;
    color: #333;
    font-weight: 600;
    margin-bottom:25px;
    text-align:center;
  }

  #signature-routes-section .column-half {
    margin-bottom:30px;
  }

  #covid-19-related-section {
    padding-top:60px;
    padding-bottom:45px;
  }

  .en .zh {
    display:none;
  }

  .en .kr {
    display:none;
  }

  .zh .en {
    display:none;
  }

  .zh .kr {
    display:none;
  }

  .kr .en {
    display:none;
  }

  .kr .zh {
    display:none;
  }

  .flags {
    box-shadow: 10px 10px 10px -6px rgba(0,0,0,0.2);
    border-radius: 10px;
    border: 1px solid #cccccc;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 5px 0 5px;
    position:relative;
  }

  .flags:hover {
    cursor:pointer;
  }

  .flag-info {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(255,255,255,0.8);
    border-radius: 15px;
    display:none;
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  } 

  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  .flags:hover .flag-info {
    display:block;
    animation: fadeIn ease 300ms;
    -webkit-animation: fadeIn ease 300ms;
    -moz-animation: fadeIn ease 300ms;
    -o-animation: fadeIn ease 300ms;
    -ms-animation: fadeIn ease 300ms;
  }

  #signature-routes-section .flag-info p {
    margin-bottom:7px;
  }

  #signature-routes-section .flag-info .flag-info-header {
    margin-top: 35px;
    font-size: 16px;
  }

  #signature-routes-section .flag-info .flag-info-footer {
    margin-top: 35px;
    font-size: 16px;
  }


  #our-solutions-section h2 {
    text-align:center;
    font-size: 25px;
    color: #333;
    font-weight: 600;
    margin-bottom: 25px;
  }


  #our-solutions-section img {
    margin-bottom:30px;
  }

  #our-solutions-section img.shipkoo-tracking {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    border:1px solid #ccc;
    width:100%;
    margin-bottom:0;
  }

  #our-solutions-section {
    padding-top: 50px;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 25px;
  }

  #our-solutions-section p{
    text-align: center;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    color: #4b4b4b;
    font-size: 15px;
    font-weight: 400;
    line-height:1.5;
    margin-bottom:25px;
  }

  a {
    color:#00af85;
  }

  .onhover {
    margin-top:-10px;
  }

  @keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  @media only screen and (max-width:981px) {
    
    #find-solutions-section {
      padding-left:25px;
      padding-right:25px;
      padding-top:25px;
    }

    #find-solutions-section h2 {
      font-size:28px;
      margin-bottom:20px;
    }

    #find-solutions-section h1 {
      font-size:28px;
      margin-bottom:20px;
    }

    #find-solutions-section .column-half {
      width: 100%;
      padding: 0;
    }

    #find-solutions-section .column-half.last {
      width: 100%;
      padding: 0;
    }

    #our-solutions-section {
      padding-top:20px;
      padding-bottom:0;
    }

    #our-solutions-section h2 {
      font-size:25px;
      margin-bottom:20px;
    }

    #our-solutions-section {
      padding-right:25px;
      padding-left:25px;
    }

    #our-solutions-section h2 {
        margin-bottom: 20px;;
    }

    #our-solutions-section p {
        margin-bottom: 15px;
    }

    ${ArrowButton} { 
      margin-top:30px;
    }

    #signature-routes-section {
      padding-left:25px;
      padding-right:25px;
      padding-top:20px;
      padding-bottom:0;
    }

    #signature-routes-section h2 {
      font-size:25px;
      margin-bottom:20px;
    }

    #signature-routes-section .column-half {
      width: 100%;
      padding: 0;
    }

    #signature-routes-section .column-half.last {
      width: 100%;
      padding: 0;    
      padding-top: 20px;
    }

    #find-solutions-section {
      padding-left:25px;
      padding-right:25px;
      padding-top:25px;
    }

    #find-solutions-section h2 {
      font-size:28px;
      margin-bottom:20px;
    }

    #covid-19-related-section { 
      padding-top:30px;
    }

    .column-fourth {
      width:100%;
      padding:0;
    }

    .route-line {
      display:none;
    }

    .column-third { 
      width:100%;
      margin-right:0;
    }

    #signature-routes-section .column-half {
      margin-bottom: 0;
    }

    .flags { 
      margin-bottom:20px;
    }

    #covid-19-related-section {
      padding-left:25px;
      padding-right:25px;
    }

    #find-solutions-section img {
      margin-top: 0;
    }
  }

`;

const ContentSmall = styled.div<TextDesc>`
  position: relative;
  color: #4b4b4b;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:25px;
`;

const ContentSmallSpan = styled.span<TextDesc>`
  position: relative;
  color: #4b4b4b;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
`;

const Clear  = styled.div<BasicDiv>`
  clear:both;
  padding:0 !important;
`;

const Row  = styled.div<BasicDiv>`
  max-width:1080px;
  margin-left:auto;
  margin-right:auto;
`;

const HeroHeader = styled.h1`
  font-size: 35px;
  margin-bottom: 30px;
  color:#fff;
  font-weight:500;
`;

const HeroText = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: #7f7f7f;
  line-height:1.8;
`;

const InternationalShipping = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        {/* <title>Make Shipping and Fulfillment Easier During the COVID-19 Disruption | Shipkoo</title> */}
        <title>{intl.formatMessage({ id: "meta-title-covid-19" })}</title>
        <link rel="alternate" href={intl.formatMessage({ id: "covid19-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "covid19-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "covid19-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "covid19-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="" />
        <meta property="og:title" content={intl.formatMessage({ id: "meta-title-covid-19" })} />
        <meta property="og:description" content="" />
        <meta name="twitter:title" content={intl.formatMessage({ id: "meta-title-covid-19" })} />
        <meta name="twitter:description" content="" />
      </Helmet>
      <PageContainer>
        <MainSection>
          <div id="hero-section">
            <img className="hero-bg" src={HeroBg} alt="international shipping hero background" />
            <Row>
                <div className="column">
                  <HeroHeader>{intl.formatMessage({ id: "covid19-header1" })} <br />{intl.formatMessage({ id: "covid19-header2" })}</HeroHeader>
                </div>
                <Clear></Clear>
            </Row>
          </div>  
        </MainSection>        
        <Covid19DisruptionSection>
          <div id="find-solutions-section">
            <Row>
              <h2>
                {intl.formatMessage({ id: "covid19-solutions-title" })}
              </h2>
            </Row>
            <Row>
              <div className="column-half">
                <ContentSmall>
                  {intl.formatMessage({ id: "covid19-solutions-desc1" })}
                </ContentSmall>
                <ContentSmall>
                  {intl.formatMessage({ id: "covid19-solutions-desc2" })}
                </ContentSmall>
              </div>
              <div className="column-half last">
                <img src={FindSolutionsImg} alt="find solutions for the covid19 impact" />
              </div>
              <Clear></Clear>
            </Row> 
          </div>
        </Covid19DisruptionSection>
        <Covid19DisruptionSection>
          <div id="our-solutions-section" className="text-center">
            <Row>
              <h2>
                {intl.formatMessage({ id: "covid19-shipkoo-express-title" })}
              </h2>
              <ContentSmall>
                {intl.formatMessage({ id: "covid19-shipkoo-express-desc1" })}
              </ContentSmall>  
              <ContentSmall>
                {intl.formatMessage({ id: "covid19-shipkoo-express-desc2" })}
              </ContentSmall>
              <img src={OurSolutionsImg} alt="our solution for the covid19 impact" />
              <ContentSmall>
                {intl.formatMessage({ id: "covid19-shipkoo-express-desc3" })}
              </ContentSmall>
              <ContentSmall>
                {intl.formatMessage({ id: "covid19-shipkoo-express-desc4" })} 
              </ContentSmall>
              <img src={ShipkooTrackingImg} className="shipkoo-tracking" alt="shipkoo tracking" />
            </Row>
          </div>
        </Covid19DisruptionSection>
        <Covid19DisruptionSection>
          <div id="signature-routes-section">
            <Row>
              <h2>
                {intl.formatMessage({ id: "covid19-signature-route-title" })}
              </h2>
              <p>
                {intl.formatMessage({ id: "covid19-signature-route-list-title" })} 
              </p>
              <ul>
                <li>{intl.formatMessage({ id: "covid19-signature-route-list1" })}</li>
                <li>{intl.formatMessage({ id: "covid19-signature-route-list2" })}</li>
                <li>{intl.formatMessage({ id: "covid19-signature-route-list3" })}</li>
              </ul>
            </Row>
            <Row> 
              <div className="column-third">
                <img className="route-icon" src={RouteIcon1} alt="route icon 1" />
              </div>
              <div className="column-third">
                <img className="route-icon" src={RouteIcon2} alt="route icon 2" />
              </div>
              <div className="column-third last">
                <img className="route-icon" src={RouteIcon3} alt="route icon 3" />
              </div>
              <Clear></Clear>
              <img src={RouteLine} alt="route-line" className="route-line" />
              <div className="column-half">
                <img className="route-icon" src={RouteIcon4} alt="route icon 4" />
              </div>
              <div className="column-half last">
                <img className="route-icon" src={RouteIcon5} alt="route icon 5" />
              </div>
              <Clear></Clear>
            </Row>
            <Row className="text-center">
              <ContentSmall>
                {intl.formatMessage({ id: "covid19-desc" })}
              </ContentSmall>
              <div className="column-fourth">
                <div className="flags">
                  <div className="flag-info">
                    <p className="flag-info-header"> 
                      <strong>
                        {intl.formatMessage({ id: "covid19-us-title" })}
                      </strong>
                    </p>
                    <p>{intl.formatMessage({ id: "covid19-us-list1" })}</p>
                    <p>{intl.formatMessage({ id: "covid19-us-list2" })}</p>
                    <p>{intl.formatMessage({ id: "covid19-us-list3" })}</p>
                    <p className="flag-info-footer">
                      <strong>
                        {intl.formatMessage({ id: "covid19-us-bottom-title" })}
                      </strong>
                    </p>
                  </div>
                  <img src={USflag} alt="us flag" />
                </div> 
              </div>
              <div className="column-fourth">
                <div className="flags">
                  <div className="flag-info">
                    <p className="flag-info-header"> 
                      <strong>
                        {intl.formatMessage({ id: "covid19-uk-title" })}
                      </strong>
                    </p>
                    <p>{intl.formatMessage({ id: "covid19-uk-list1" })}</p>
                    <p>{intl.formatMessage({ id: "covid19-uk-list2" })}</p>
                    <p>{intl.formatMessage({ id: "covid19-uk-list3" })}</p>
                    <p className="flag-info-footer">
                      <strong>
                        {intl.formatMessage({ id: "covid19-uk-bottom-title" })}
                      </strong>
                    </p>
                  </div>
                  <img src={UKflag} alt="uk flag" />
                </div>
              </div>
              <div className="column-fourth">
                <div className="flags">
                  <div className="flag-info">
                    <p className="flag-info-header"> 
                      <strong>
                        {intl.formatMessage({ id: "covid19-ger-title" })}
                      </strong>
                    </p>
                    <p>{intl.formatMessage({ id: "covid19-ger-list1" })}</p>
                    <p>{intl.formatMessage({ id: "covid19-ger-list2" })}</p>
                    <p>{intl.formatMessage({ id: "covid19-ger-list3" })}</p>
                    <p className="flag-info-footer">
                      <strong>
                        {intl.formatMessage({ id: "covid19-ger-bottom-title" })}
                      </strong>
                    </p>
                  </div>
                  <img src={Germanyflag} alt="germany flag" />
                </div>
              </div>
              <div className="column-fourth last">
                <div className="flags">
                  <div className="flag-info">
                    <p className="flag-info-header"> 
                      <strong>
                        {intl.formatMessage({ id: "covid19-kor-title" })}
                      </strong>
                    </p>
                    <p>{intl.formatMessage({ id: "covid19-kor-list1" })}</p>
                    <p>{intl.formatMessage({ id: "covid19-kor-list2" })}</p>
                    <p>{intl.formatMessage({ id: "covid19-kor-list3" })}</p>
                    <p className="flag-info-footer">
                      <strong>
                        {intl.formatMessage({ id: "covid19-kor-bottom-title" })}
                      </strong>
                    </p>
                  </div>
                  <img src={Koreaflag} alt="korea flag" />
                </div>
              </div>
              <Clear></Clear>
              <Link to="/contact">
                <ArrowButton >{intl.formatMessage({ id: "covid19-free-quote-button" })}</ArrowButton>
              </Link>
            </Row>
          </div>
        </Covid19DisruptionSection>
        <Covid19DisruptionSection>
          <div id="covid-19-related-section" className={intl.formatMessage({ id: "language" })}>
            <Row className="text-center">
              <h2>{intl.formatMessage({ id: "covid19-related-title" })}</h2>
              <ContentSmall className="en">
                For real-time information about the Coronavirus pandemic and COVID-19 disease, please visit the <a href="https://www.who.int/" target="_blank" rel="noreferrer">World Health Organization website (WHO)</a>. It is a great time now for businesses to utilize eCommerce platform to sell their products. If you are new to eCommerce, you may consider to try out dropshipping first. If you are just planning to seek for a cost-effective shipping solution during the Coronavirus pandemic, we are here to help! Here are some resources to either get you started or help you improve your shipping process. 
              </ContentSmall>
              <ContentSmall className="zh">
                有关新冠肺炎的实时信息，请浏览世界卫生组织官方网站。如果您是电子商务的新手，您可以考虑先尝试一件代发。如果您已经有自己的电商平台并且想要寻求经济实惠的运输解决方案，我们随时为您提供帮助和免费报价！
              </ContentSmall>
              <ContentSmall className="kr">
                코로나바이러스 대유행과 COVID-19 질병에 대한 실시간 정보는 <a href="https://www.who.int/" target="_blank" rel="noreferrer"> 세계보건기구(WHO)</a> 웹사이트를 방문하십시오. 지금은 기업들이 eCommerce 플랫폼을 활용해 제품을 판매할 수 있는 절호의 시간이다. 만약 당신이 eCommerce를 처음 접하는 사람이라면, 당신은 먼저 드랍쉬핑을 시도해 보는 것을 고려할 수 있다. 만약 당신이 코로나바이러스 대유행 중에 비용 효과적인 운송 해결책을 찾고자 한다면, 우리는 도움을 주기 위해 여기에 있다! 다음은 배송 과정을 시작하거나 개선하는 데 도움이 되는 몇 가지 리소스 입니다.
              </ContentSmall>
            </Row>
            <BlogRow className="blog-container">  
              <PostsWrapper>
                {blogPosts.map(post => (
                  <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
                ))}
              </PostsWrapper>
            </BlogRow>
          </div>
        </Covid19DisruptionSection>
      </PageContainer>
    </Layout>
  );
};

export default InternationalShipping;
