import USChinaImageSource from 'images/featured-image/US-China.jpg';
import CargoImageSource from 'images/featured-image/Cargo2.jpg';
import GoodInventoryImageSource from 'images/featured-image/good-inventory.jpg';
import DamagedItemImageSource from 'images/featured-image/damaged-item.jpg';
import ShipLiquidImageSource from 'images/featured-image/how-to-ship-liquids.jpg';
import ImpactOfCoronaImageSource from 'images/featured-image/impact-of-the-coronavirus.jpg';
import QuickGuideImageSource from 'images/featured-image/warehouse-and-fulfillment-featured.png';
import TwoCountriesImgSource from 'images/featured-image/n95-mask-china-quality.png';
import DropshippingImgSource from 'images/featured-image/dropshipping.jpg';
import ECommerceThrivingImgSource from 'images/featured-image/e-commerce-continuously-thriving.png';
import FutureDevelopmentImgSource from 'images/featured-image/future-development-of-the-cross-border-ecommerce-logistics-market.png';
import FacebookShopsImg from 'images/featured-image/facebook-shops-shipkoo.png';
import MajorEcommercePlatformFeatured from 'images/featured-image/major-ecommerce-platforms.png';
import AvoidDeliveryDelaysFeatured from 'images/featured-image/how-to-avoid-delivery-delays-featured.png';
import HotSellingProductsFeatured from 'images/featured-image/hot-selling-disinfection-product.png';

export interface BlogPostI {
  id: string;
  title: string;
  date: string;
  imgSrc: string;
}

export const blogPosts: BlogPostI[] = [
  {
    id: 'hot-selling-products-in-the-post-covid-world',
    date: '2020-07-27',
    title: 'Hot-Selling Products in the Post-COVID World',
    alt: 'shipkoo hot selling disinfection products',
    imgSrc: HotSellingProductsFeatured,
  },
  {
    id: 'how-to-avoid-delivery-delays',
    date: '2020-06-24',
    title: 'How to Avoid Delivery Delays',
    alt: 'how to avoid delivery delays',
    imgSrc: AvoidDeliveryDelaysFeatured,
  },
  {
    id: 'how-did-the-major-ecommerce-platforms-survive-during-the-covid-19-pandemic',
    date: '2020-06-11',
    title: 'How Did The Major eCommerce Platforms Survive During the COVID-19 Pandemic?',
    alt: 'online shopping major ecommerce platforms',
    imgSrc: MajorEcommercePlatformFeatured,
  },
  {
    id: 'facebook-shops-and-tiktok-continues-to-be-hot-in-2020',
    date: '2020-06-04',
    title: 'Facebook Shops and TikTok Continues to be hot in 2020',
    alt: 'facebook shops shipkoo',
    imgSrc: FacebookShopsImg,
  },
  {
    id: 'e-commerce-continuously-thriving-during-pandemic',
    date: '2020-05-21',
    title: 'E-Commerce Continuously Thriving During Pandemic',
    alt: 'e-commerce continuously thriving during pandemic',
    imgSrc: ECommerceThrivingImgSource,
  },
  {
    id: 'protection-mask-standards-equivalent-to-european',
    date: '2020-04-30',
    title: 'Two Countries Announced China’s Protection Mask Standards Equivalent to European Ones',
    alt: 'n95 mask china quality',
    imgSrc: TwoCountriesImgSource,
  },
  {
    id: 'the-impact-of-the-coronavirus-on-global-supply-chains',
    date: '2020-04-10',
    title: 'The Impact of the Coronavirus on Global Supply Chains',
    alt: 'the impact of the coronavirus on global supply chain',
    imgSrc: ImpactOfCoronaImageSource,
  },
];